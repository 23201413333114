<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <!-- <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入banner名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button> -->
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>

    <el-table border :data="tableData" height="76vh" style="width: 99%" @sort-change="changeSort">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="productName" label="名称"></el-table-column>
      <el-table-column prop="productUrl" label="图标">
        <template slot-scope="scope">
          <img :width="80" :src="scope.row.productUrl" />
        </template>
      </el-table-column>
      <el-table-column prop="goldCoinsNum" label="金币数量"></el-table-column>
      <el-table-column prop="productPrice" label="商品价格"></el-table-column>
      <el-table-column prop="productId" label="服务商商品id"></el-table-column>
      <el-table-column prop="payType" label="支付类型">
        <template slot-scope="scope">
          <span>{{scope.row.payType===1?'apple':'google'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="discount" label="是否折扣">
        <template slot-scope="scope">
          <span>{{scope.row.discount===1?'是':'否'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog :title="`${ruleForm.id?'编辑':'添加'}商品`" :visible.sync="addVisible" width="600px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item label="商品名称" prop="productName" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.productName"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="productUrl" :rules="rules.typeIcon">
          <uploadImg @change="uploadImgChange" :imageFile="[{url:ruleForm.productUrl}]"></uploadImg>
        </el-form-item>
        <el-form-item label="金币数量" prop="goldCoinsNum" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model.number="ruleForm.goldCoinsNum"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品价格" prop="productPrice" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            type="number"
            v-model.number="ruleForm.productPrice"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务商商品id" prop="productId" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.productId"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付类型" prop="payType" :rules="rules.changeRule">
          <el-radio-group v-model="ruleForm.payType">
            <el-radio :label="1">apple</el-radio>
            <el-radio :label="2">google</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否折扣" prop="discount" :rules="rules.changeRule">
          <el-radio-group v-model="ruleForm.discount">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {
        content: "",
        id:"",
        img:"",
        jumpConfig:"",
        name:"",
      },
      searchForm: {
        name:null,
        sortOrder:null,
        sortField:null,
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
  },
  methods: {
    addFunc(){
      this.ruleForm = {}
      this.addVisible = true;
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.productUrl=val[0].url
      }
    },
    toEdit(data) {
      this.ruleForm = {...data};
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.payProductSave({...this.ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.payProductList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    changeSort(val) {
      console.log(val)
      if (val.order) {
        this.searchForm.sortOrder = val.order === "ascending" ? 'ascend' : 'descend';
        this.searchForm.sortField = val.prop;
      } else {
        this.searchForm.sortOrder = "";
        this.searchForm.sortField = "";
      }
      this.getTableList(true);
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.payProductDel({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>

</style>
