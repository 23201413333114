import { render, staticRenderFns } from "./goodsList.vue?vue&type=template&id=3bcdf412&scoped=true"
import script from "./goodsList.vue?vue&type=script&lang=js"
export * from "./goodsList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_ckru7f3yypsoan6edyu23zfbiq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bcdf412",
  null
  
)

export default component.exports